/*------------------------------------------------------------------------
# author    Eric Worman, News Media Corporation
# copyright Copyright  2015. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website   Newport Chamber
# Path: /resources/assets/less/core.less
-------------------------------------------------------------------------*/

/* Import 3L mixins and apply reset.css
********************************************/
@import '3L/3L';

.reset();



/* COLORS
********************************************/

@btnBgHover: #e96642;
@btnBorderHover: #d65037;
@btnBorder: #cfcecf;

@darkGray: #757575; /* 117/117/117 */
@black: #333333;

/* TAG STYLES
********************************************/
a, a:focus, a:hover { color: currentcolor; }
img { max-width:100%; vertical-align:middle; }
.h2, h2 { font-size:3.75em; line-height:1.2em; margin-bottom:5px; }
.h3, h3 { font-size:1.875em; }
.h4, h4 { font-size: 1.75em; }
strong { font-weight:bold; }
em { font-style:italic; }

dl dt { margin-top:30px; }
dl dd { margin-top:10px; }


.contentSection article > ul { list-style-type:disc; }
.contentSection article > ul > li { text-align:left; margin-left:25px; }
.contentSection article a { text-decoration:underline; }



/* HELPER CLASSES
********************************************/

.fl {float:left;}
.fr {float:right;}
.fn {float:none;}


.link { display:inline-block; float: none; font-size: 1.2em; line-height: 2.5em; padding: 10px 50px; margin:10px 0; text-align: center; border:1px solid @btnBorder; }
.link:hover { background-color:@btnBgHover; border: 1px solid @btnBorder; color:white; }


/* DEFAULT THEME CLASSES
********************************************/

.solid { background-color:#e96642; border:1px solid #d65037; border-bottom:4px solid #d65037; color:white; }
.outlineWhite { background-color:transparent; border:1px solid white; color:white; }
.outlineBlack { background-color:transparent; border:1px solid black; color:black; }

.textLeft { text-align:left !important; }
.textCenter { text-align:center !important; }
.textRight { text-align:right !important;}

/* Navigation Menu Items */
#nav > ul > li.navLink > a { color:@black; }
#nav > ul > li.navLink:hover > a { text-decoration:underline; }
#nav > ul > li.navButton { background-color: @darkGray; border: 1px solid @black; border-bottom:4px solid @black; font-size: 1em;  margin: 0; padding: 0 20px;}
#nav > ul > li.navButton > a { color:white; }

.image-padding-bottom { padding-bottom:40px; }
.image-padding-right { padding-right:40px; }
.image-padding-left { padding-left:40px; }
.image-padding-top { padding-top:40px; }



/* HEADER
********************************************/
.headerPanel { display:none; }
.header { position:relative; }
.logoContainer { float:left; font-size:1.5em; width:100%;  }
.headerLogo { max-width:5.75em; max-height:5.75em; padding:0 0.5em; }

.navbar-header { margin-top:13px; }
.navbar-header .logoContainer .headerLogo { max-height:70px; padding:5px 0; width:auto; }
.navbar-default { background-color:white; .border-radius(0); background-image:none; border:none; box-shadow:inherit; margin:0; }
.navbar-toggle { float:left; padding:15px 13px; position:absolute; top:9px; left: 5px; border:none; .border-radius(0); background-color:@black;  }
.navbar-default .navbar-toggle .icon-bar { background-color:white; }
.navbar-nav { margin-top:30px; }
.navbar-nav > li > a { padding:10px 20px; }


.navButton { background-color:@darkGray; border-bottom:4px solid @black; }
.navbar-default .navbar-nav > li.navButton > a { color:white; }

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:focus, .navbar-default .navbar-nav > .open > a:hover { background-color:inherit; color:inherit; }
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .open > a { background-image:none; .box-shadow(none); }

.navbar-nav .open .dropdown-menu { .border-radius(0); background-color:white; border:none; }



/* FOOTER
********************************************/
.footerPanel { display:none; }
.footer { padding:50px; }
.footer ol { margin:1em 0; }
.footer li { line-height:1.8em; }

.footerLogo { width:65%; margin: 1.2em auto;  }
.footerLogoContainer { text-align:center; }
.copyright { font-size:0.8em; }
.ss-icon { margin-right:5px; position:relative; top:2px; }

.footerMenu { .columns(3); }
.footerMenu a { display:block; padding:10px 0; }


/* MAIN STRUCTURE CLASSES
********************************************/

.contentSectionContainer, .header {
	width: 100%;
	box-sizing: border-box;
	padding: 0 calc(~"38% - 500px");
	min-height:100px;
}

.contentSectionContainer {

	.display-flex();  overflow: hidden;
}

.contentSection { padding:3.125em 0; .flex(1 0 auto); }
.contentSection p { margin:1.25em 0; line-height:1.4em; }

.swiper-container { padding-bottom:45px; }
.swiper-pagination-bullet { margin:0 3px; }
.swiper-pagination { left:0; right:0; bottom:15px; }

.scrollUp {
	padding:5px; .border-radius(25px); width:50px; height:50px;
	background-color:@black; .opacity(0.8); color:white;
	bottom:20px; right:20px; text-align:center; display:block;
	position: fixed; z-index: 2147483647; color:white;
}

.scrollUp .fa { margin-top:10px; color:white; }
.scrollUp:hover, .scrollUp:active { color:white; }

.inlineLink p, .inlineLink a { display:inline; }
.link.right { float:right; }
.link.left { float:left; }

.share_link { position:absolute; display:block; right:10px; z-index:1000; top:15px; }
.share_link > .fa { margin:0; }

.articlePage .share_link { display:none; }




/* FLEXBOX FIXES
********************************************/

.contentSection.col-sm-4 { .flex(0 0 33.333%) !important; }
.contentSection.col-sm-3 { .flex(0 0 25%) !important; }


/* FOOTER
********************************************/

.nmcCopyright { margin:20px; }





/* Landscape phones and down
*********************************************/
@media (max-width: 768px)
{
	img { padding:10px; }

	.fl {float:none;}
	.fr {float:none;}

	.contentSection > section { height:auto !important; }
	.siteName { display:none; }
	.logoContainer { text-align:center; }

	.navButton.open { border:none; }

	.image-padding-top, .image-padding-right, .image-padding-bottom, .image-padding-left { padding:0; }

	.contentSectionContainer, .contentSection { display:inherit; }

}