/*------------------------------------------------------------------------
# author    Eric Worman, News Media Corporation
# copyright Copyright  2014. All rights reserved.
# @license  http://www.gnu.org/licenses/gpl-2.0.html GNU/GPL
# Website DynaCMS
# Path: /css/directory.less
-------------------------------------------------------------------------*/
/* Import 3L mixins and apply reset.css
********************************************/
/* COLORS
********************************************/
/*
	Black: #1f1f1f / 31/31/31
*/
/* 125/124/124 */
/* 247/244/203 */
/* CORE DIRECTORY
********************************************/
.directoryWrapper {
  margin: auto;
}
/* CLASSIFICATION PAGE
********************************************/
.directoryHeaderContainer {
  text-align: center;
}
.eDirBtn {
  margin: auto;
  font-size: 0.8em;
}
.searchFieldContainer {
  position: relative;
  width: 330px;
  margin: 25px auto;
}
input.searchField {
  border: 1px solid #afb0af;
  color: #afb0af;
  font-style: italic;
  font-weight: normal;
  font-size: 1.2em;
  letter-spacing: 0px;
  height: auto;
  padding: 10px 15px;
}
.magGlass {
  right: 35px;
  top: 8px;
  position: absolute;
}
.directoryIndexContainer {
  text-align: center;
}
.directoryIndexContainer .index {
  display: inline-block;
  margin: 0 4px;
}
.directoryIndexContainer .index > a {
  color: #1f1f1f;
  font-size: 1.6em;
}
.directoryIndexContainer .index > a.active,
.directoryIndexContainer .index > a:hover {
  color: #5bbddd;
}
.classificationList {
  -webkit-columns: 4 20px;
  -moz-columns: 4 20px;
  columns: 4 20px;
  max-width: 1024px;
  width: 95%;
  margin: 20px auto;
}
.classificationList a {
  color: #1f1f1f;
  display: block;
  text-align: left;
  margin: 10px 0;
}
.classificationList a:first-of-type {
  margin-top: 0;
}
/* LISTINGS
********************************************/
.listingsHeader {
  width: 95%;
  max-width: 800px;
  background-color: #5bbddd;
  padding: 15px 0;
  margin: 50px auto;
  color: white;
  text-align: center;
  font-size: 2.0em;
  text-transform: uppercase;
}
.listingContainer {
  width: 95%;
  max-width: 800px;
  margin: 10px auto;
  padding: 20px 0 30px;
  border-bottom: 1px solid #1f1f1f;
}
.listingContainer .btn {
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px 20px;
}
.listingContainer .row {
  position: relative;
}
.businessName {
  font-size: 1em;
}
.ownerName {
  font-style: italic;
  font-size: 1em;
}
.addressSpacer {
  margin-top: 10px;
}
.address {
  font-size: 1em;
}
.phone {
  margin-top: 10px;
}
.className {
  font-style: italic;
  font-size: 1em;
  position: absolute;
  bottom: -15px;
  left: 15px;
}
.contactBlock {
  clear: both;
  padding-top: 1px;
}
.contactBlock .address {
  font-size: 1.0em;
  margin: 10px 0;
}
.raquo {
  position: relative;
  top: -1px;
}
.contactIcon {
  width: 25px;
  position: relative;
}
.phoneIcon {
  top: 5px;
}
.emailLink {
  position: relative;
  top: 16px;
}
.directoryAdButton {
  margin-top: 10px;
}
